import React from "react";

const MSMEBanner = () => {
	return (
		<div className="d-flex justify-content-center align-content-center ">
			<div
				style={{
					backgroundColor: "#f2fcfc"
				}}
				className="shadow text-muted  rounded-5 p-4 w-50"
			>
				<p
					style={{ fontFamily: "sans-serif" }}
					className=" text-center fw-bolder fs-5 text-opacity-90"
				>
					X-workz holds official registration with the Ministry of Micro, Small
					& Medium Enterprises (MSME), reflecting its commitment to regulatory
					compliance and industry excellence.
				</p>
			</div>
		</div>
	);
};

export default MSMEBanner;
